import { Link } from "@remix-run/react";
import { DateTime } from "luxon";

export default function Footer() {
  const currentYear = DateTime.now().toFormat("yyyy");

  return (
    <footer className="site-footer bg-dark">
      <div className="container">
        <ul className="nav justify-content-center">
          {/* <li className="nav-item">
            <a className="nav-link" href="/feedback">
              Feedback
            </a>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="/login">
              Admin
            </Link>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="page-contact.html">
              Contact
            </a>
          </li> */}
        </ul>
        <div className="copy">
          &copy; Year29 {currentYear}
          <br />
          All rights reserved
        </div>
      </div>
    </footer>
  );
}
