import { LinksFunction, type LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";

import globalStyles from "~/styles/milo.scss?url";
import Footer from "./common/components/footer";
import Header from "./common/components/header";
import { authenticator } from "./services/auth.server";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: globalStyles },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request);

  return {
    user,
  };
}

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useRouteLoaderData<typeof loader>("root");

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          defer
          data-domain="year29.com"
          src="https://plausible.io/js/script.js"
        ></script>
      </head>
      <body>
        <Header user={loaderData?.user} />
        <main className="main pt-4">
          <div className="container">{children}</div>
        </main>
        <Footer />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (error?.status === 404) {
    return (
      <div className="card">
        <header className="card-header text-center">
          <h1 className="card-title">Not Found</h1>
        </header>
        <div className="card-body">
          <p className="text-center">
            Sorry, the page you are looking for does not exist.
          </p>
        </div>
      </div>
    );
  }

  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
