import { Form, Link } from "@remix-run/react";
import type { User } from "~/services/auth.server";

type HeaderProps = {
  user: User | null | undefined;
};

export default function Header({ user }: HeaderProps) {
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light bg-white absolute-top">
        <div className="container">
          <button
            className="navbar-toggler order-2 order-md-1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse"
            aria-controls="navbar-left navbar-right"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse order-3 order-md-2">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>

              {user ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/posts/create">
                    New Post
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>

          <Link className="navbar-brand mx-auto order-1 order-md-3" to="/">
            Year29
          </Link>

          <div className="collapse navbar-collapse order-4 order-md-4">
            <ul className="navbar-nav ms-auto">
              {/* <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li> */}
            </ul>

            {/* <form className="form-inline" role="search">
              <input
                className="search js-search form-control form-control-rounded me-sm-2"
                type="text"
                title="Enter search query here.."
                placeholder="Search.."
                aria-label="Search"
              />
            </form> */}

            {user ? (
              <Form method="post" action="/logout" className="form-inline">
                <button type="submit" className="btn btn-primary">
                  Logout
                </button>
              </Form>
            ) : null}
          </div>
        </div>
      </nav>
    </header>
  );
}
